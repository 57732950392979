import { __assign, __awaiter, __generator } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { InsurancePageModal, InsuranceUpsaleModal } from '../../../InsurancePage';
import { ThemeProvider, useTheme } from '../../../theme';
import { Money } from '../../../Money';
import { useInsuranceGroupTexts, useInsuranceUpsale } from './hooks';
import SimpleLoader from '../../../SimpleLoader';
import Modal from '../../../Modal';
import ServiceSummaryDivider from '../../../ServiceSummary/components/ServiceSummaryDivider/ServiceSummaryDivider';
import ServiceSummaryOption from '../../../ServiceSummary/components/ServiceSummaryOption';
import InsuranceSummary from '../../../ServiceSummary/services/InsuranceSummary';
import Button from '../../../Button';
import { Included } from '../../../Icons';
import { OptionsList } from '../../../InsurancePage/components/OptionsList/OptionsList';
import { getInsuranceGroupNameAttr } from './utils';
var InsuranceUpsale = function (_a) {
    var insurancePrograms = _a.insurancePrograms, oldVersion = _a.oldVersion, groupName = _a.groupName;
    var t = useTranslation('Checkout').t;
    var theme = useTheme('CheckoutAdditionalService').InsuranceUpsale;
    var model = useInsuranceUpsale(insurancePrograms);
    var groupTexts = useInsuranceGroupTexts(groupName, insurancePrograms);
    var onServiceDelete = function (_, service) { return __awaiter(void 0, void 0, void 0, function () {
        var insuranceService;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    insuranceService = service;
                    return [4 /*yield*/, model.onDeleteInsurance(insuranceService.code)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var renderPriceDescription = function (isSelected) {
        return isSelected ? (model.totalPrice.amount > 0 && (React.createElement("div", { className: theme.price },
            React.createElement("span", { className: theme.price__label }, t('Total')),
            React.createElement(Money, { moneyClassName: theme.money, money: model.totalPrice }),
            React.cloneElement(Included, { className: theme.includedIcon })))) : (React.createElement(React.Fragment, null,
            React.createElement("span", { className: theme.forAll }, t('For all passengers')),
            React.createElement("div", { className: theme.price },
                model.isDifferentPrices && React.createElement("span", { className: theme.price__label }, t('from')),
                React.createElement(Money, { moneyClassName: theme.money, money: model.minPrice }))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(InsuranceSummary, { flight: model.order.flight, travellers: model.order.travellers, services: model.insuranceSummaryServices, onServiceDelete: model.totalPrice.amount > 0 ? onServiceDelete : null, isConfirmed: model.isConfirmed, isDownload: true }, function (items) {
            var _a, _b;
            var isSelected = items.length > 0;
            return (React.createElement(ThemeProvider, { value: {
                    ServiceSummary: {
                        ServiceSummaryOption: {
                            option__name: theme.option__name,
                            option__price: theme.option__price,
                            option__price_icon: theme.option__price_icon,
                            option__download: theme.option__download,
                            option__iconIncluded: theme.option__iconIncluded
                        }
                    }
                } },
                React.createElement("div", __assign({ className: cn(theme.container, (_a = {},
                        _a[theme.container_selected] = isSelected,
                        _a[theme.oldVersion] = oldVersion,
                        _a[theme.container_loading] = model.isLoading,
                        _a), groupName) }, getInsuranceGroupNameAttr(groupName)),
                    React.createElement("div", { className: theme.header },
                        groupTexts.title,
                        oldVersion && renderPriceDescription(isSelected)),
                    React.createElement("div", { className: theme.content },
                        isSelected && model.order.travellers.length > 1 && (React.createElement("div", { className: theme.label }, t('For all passengers'))),
                        isSelected ? (React.createElement(ServiceSummaryDivider, null,
                            React.createElement(ServiceSummaryOption, { groupOption: { items: items } }))) : (React.createElement("span", { dangerouslySetInnerHTML: { __html: groupTexts.descr } })),
                        !isSelected && (React.createElement(OptionsList, { className: theme.options, options: groupTexts.options }))),
                    React.createElement("div", { className: theme.footer },
                        !oldVersion && (React.createElement("div", { className: theme.info }, renderPriceDescription(isSelected))),
                        React.createElement("div", { className: theme.controls },
                            React.createElement(Button, { className: cn(theme.button, (_b = {},
                                    _b[theme.change] = isSelected,
                                    _b[theme.loading] = model.isLoading,
                                    _b)), onClick: model.singleInsurance
                                    ? isSelected
                                        ? model.onDeleteSingle
                                        : model.onAddSingle
                                    : model.open, isLoading: model.isLoading }, model.singleInsurance
                                ? isSelected
                                    ? t('Remove protection')
                                    : t('Add protection')
                                : isSelected
                                    ? t('Change')
                                    : t('Add protection')))))));
        }),
        React.createElement(Modal, { classes: { scrollBody: theme.scrollBody, paper: theme.paper }, open: model.isOpen, fullScreen: true },
            React.createElement(InsurancePageModal, { availablePrograms: model.availablePrograms, onServicesChanged: model.setIsNeedToSendSaveReq, onTotalInsurancePriceChange: model.setInsurancePriceToPay, onSaveOrderServicesParamsChange: model.setSaveOrderServicesParams, totalPrice: model.insurancePriceToPay, onConfirm: model.onConfirm, onClose: model.onClose }),
            model.isLoading && React.createElement(SimpleLoader, null)),
        React.createElement(InsuranceUpsaleModal, { insurance: model.priorityInsurance, isOpen: model.isUpsaleOpen, onAgree: model.handleAgree, onDisagree: model.handleDisagree })));
};
export default InsuranceUpsale;
