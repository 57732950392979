import Airplane from './Airplane/Airplane.css';
import Passengers from './Passengers/Passengers.css';
import Component from './Component/Component.css';
import Disclaimer from './Disclaimer/Disclaimer.css';
import SeatMap from './SeatMap/SeatMap.css';
import SeatInfo from './SeatInfo/SeatInfo.css';
import DoubleSeat from './DoubleSeat/DoubleSeat.css';
import SeatServiceSlide from './SeatServiceSlide/SeatServiceSlide.css';
var seatMapStyles = {
    AirplaneStyles: {
        airplane__info: Airplane.airplane__info,
        airplane__name: Airplane.airplane__name,
        details: Airplane.airplane__details
    },
    SeatMapDisclaimerStyles: {
        disclaimer__wrp: Disclaimer.disclaimer,
        disclaimer: Disclaimer.disclaimer__root
    },
    ComponentStyles: {
        wrapper: Component.wrapper,
        right: Component.right,
        unavailable__button: Component.unavailable__button
    },
    DoubleSeat: {
        container: DoubleSeat.container,
        description: DoubleSeat.description,
        switch__control: DoubleSeat.switch__control,
        switch__active: DoubleSeat.switch__active,
        switch__icon: DoubleSeat.switch__icon,
        switch__label: DoubleSeat.switch__label
    },
    SureModal: {
        button: DoubleSeat.sureModalButton
    },
    Passenger: {
        item: Passengers.passenger,
        item_active: Passengers.active,
        item_recommend: Passengers.passenger_recommend,
        item_withSeat: Passengers.item_withSeat,
        payload: Passengers.payload,
        avatar: Passengers.avatar,
        money: Passengers.money,
        clear: Passengers.clear,
        select: Passengers.select
    },
    MobilePassenger: {
        active: Passengers.mobilePassenger__active,
        doubleSeat: Passengers.mobilePassenger__doubleSeat
    },
    PassengersStyles: {
        recommend__icon: Passengers.recommend__icon,
        recommend__label: Passengers.recommend__label,
        passengers__button: Passengers.passengers__button,
        notifications: Passengers.notifications,
        notification: Passengers.notification
    },
    SeatMapStyles: {
        seatMap: SeatMap.seatMap,
        row: SeatMap.row,
        row__PartWrpWithTechFacilities: SeatMap.row__PartWrpWithTechFacilities,
        wings: SeatMap.wings,
        seatMap__wrapper: SeatMap.seatMap__wrapper,
        row__seatWrapper_newClass: SeatMap.seatWrapper_newClass,
        row__serviceHeader: SeatMap.row__serviceHeader,
        row__serviceHeader__wrapper: SeatMap.row__serviceHeader__wrapper,
        row__serviceHeader_preferred: SeatMap.row__serviceHeader_preferred,
        exit__label: SeatMap.exit__label,
        exit: SeatMap.exit,
        row__rowPartWrp: SeatMap.row__rowPartWrp,
        row_withExit: SeatMap.row_withExit,
        row__toilet: SeatMap.row__toilet,
        seatMap_wide: SeatMap.seatMap_wide
    },
    SeatTooltipStyles: {
        tooltip: SeatMap.tooltip,
        tooltip_recommend: SeatMap.tooltip_recommend,
        tooltip_withInfo: SeatMap.tooltip_withInfo,
        info: SeatMap.info
    },
    SeatsInfoStyles: {
        seat: SeatInfo.seat,
        seat__info: SeatInfo.seat__info,
        additionalInfo: SeatInfo.additionalInfo,
        description: SeatInfo.description,
        features: SeatInfo.features,
        price__wrapper: SeatInfo.price__wrapper,
        price: SeatInfo.price,
        title: SeatInfo.title,
        seat_preferred: SeatInfo.seat_preferred
    },
    SeatServiceSlide: {
        single: SeatServiceSlide.single
    },
    Controls: {
        button: SeatMap.buttonControl
    },
    SeatStyles: {
        seat: SeatMap.seat,
        seat_small: SeatMap.seat_small,
        seat_unavailable: SeatMap.seat_unavailable,
        seat_business: SeatMap.seat_business,
        seat_standart: SeatMap.seat_standart,
        seat_occupied: SeatMap.seat_occupied,
        seat_free: SeatMap.seat_free,
        seat_preferred: SeatMap.seat_preferred,
        seat_combinedRight: SeatMap.seat_combinedRight,
        seat_combinedLeft: SeatMap.seat_combinedLeft,
        number: SeatMap.number,
        seat_unexistent: SeatMap.seat_unexistent,
        seat_confirmed: SeatMap.seat_confirmed,
        seat_recommend: SeatMap.seat_recommend,
        seat_recommendCurrent: SeatMap.seat_recommendCurrent,
        seat_aisle: SeatMap.seat_aisle
    }
};
export default seatMapStyles;
